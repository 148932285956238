<template>
  <div class="text-center">
    <div class="my-5 title">, neem contact op met Censo of probeer
      het later opnieuw
    </div>
  </div>
</template>

<script>

export default {
  name: 'no-organisation',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
